import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {IdTokenService} from "../service/id-token.service";
import {CandidateStateService} from "../service/candidate-state.service";
import {catchError, EMPTY, map, switchMap} from "rxjs";

export const slotsGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const idTokenService = inject(IdTokenService);
  const candidateStateService = inject(CandidateStateService);

  if (route.queryParams && route.queryParamMap.get('id')) {
    let token = route.queryParamMap.get('id');
    if (!idTokenService.isTokenExpired(token)) {
      return authorization(token)
    }
  }
  router.navigate(['/error'])
  return false

  function authorization(token: string) {
    return candidateStateService.authorization(token).pipe(
      switchMap((candidateInfo) => {
        localStorage.setItem("token", token);
        candidateStateService.setCandidateInfo(candidateInfo);

        if (candidateInfo.interviewId) {
          return candidateStateService.fetchIntervalsFromApi(candidateInfo.interviewId).pipe(
            map((intervals) => {
              candidateStateService.intervals = intervals;
              return true;
            })
          );
        }
        return [true];
      }),
      catchError((err) => {
        router.navigate(['/error']);
        return EMPTY;
      })
    );
  }
};
