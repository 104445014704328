import {Injectable} from '@angular/core';
import {CandidateInfo} from "../dto/candidate-info";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {CandidateSlotsDto} from "../dto/candidate-slots-dto";
import {catchError, EMPTY, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CandidateStateService {

  private candidateInfo
  private _intervals: { start: number; end: number }[] = [];
  private url = environment.apiUrl;
  constructor(private http: HttpClient) {}

  public setCandidateInfo(candidate: CandidateInfo){
    this.candidateInfo = candidate
  }

  public getCandidateInfo(){
    return this.candidateInfo;
  }

  get intervals(): { start: number; end: number }[] {
    return this._intervals;
  }

  set intervals(value: { start: number; end: number }[]) {
    this._intervals = value;
  }

  public fetchIntervalsFromApi(interviewId: string): Observable<{ start: number; end: number }[]> {
    const url = this.url + `slots/${interviewId}`;
    return this.http.get<{ start: number; end: number }[]>(url).pipe(
      catchError((error) => {
        console.error('Error getting interviewer slots:', error);
        return EMPTY;
      })
    );
  }

  authorization(token: string) {
    return this.http.get<any>(this.url + `auth/${token}`);
  }

  saveEvents(candidateSlots: CandidateSlotsDto) {
    return this.http.post<any>(this.url + "slots/save", candidateSlots);
  }

  logUser(candidateId: number, isMobile:Boolean, innerWidth: number, innerHeight :  number) {
    const data = {
      "event_date": new Date().toISOString(),
      "event_name": "Candidate opens page to provide slots",
      "login": String(candidateId),
      "event_data": {
        "isMobileDevice" : isMobile,
        "innerWidth" : innerWidth ,
        "innerHeight" : innerHeight
      }
    }
      this.http.post(this.url + "collector/log", data).subscribe();
  }
}
